import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Breadcrumb, Image, Figure } from "react-bootstrap";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

import LuteinComplexMobile from "./../../images/hepato-jluchka-header.jpg";
import LuteinComplexTablet from "./../../images/hepato-jluchka-header.jpg";
import LuteinComplexDesktop from "./../../images/hepato-jluchka-header.jpg";
import LuteinComplexXL from "./../../images/hepato-jluchka-header.jpg";

import vacuum from "./../../images/hepato-jluchka-jluchka.jpg";
import stoinov from "./../../images/hepato-jluchka-dr-vasilev.jpg";
import hepatofelin from "./../../images/hepatofelin-article-box.jpg";

const HepatofelinInovativen = () => (
  <Layout backButton={true} pageInfo={{ pageName: "hepatofelin-inovativen" }}>
    <Seo title="Иновативен подход укротява жлъчката" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              ИНОВАТИВЕН ПОДХОД УКРОТЯВА ЖЛЪЧКАТА
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Иновативен подход укротява жлъчката"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="mb-4">
          <h5 className="bg-danger text-white p-2 font-light">
            Пряко включване от отделението по „Гастроентерология“
          </h5>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Иновативен подход укротява жлъчката
          </h1>
          <h2 className="font-light">
            <em>Работи безотказно, твърдят специалисти</em>
          </h2>
        </Col>
        <Col sm={9}>
          <p>
            Бум на проблемите с жлъчката се наблюдава последните години,
            отбелязват запознати. Проблемът често остава скрит за пострадалия, а
            липсата на навременна профилактика и лечение бързо усложнява
            ситуацията. Когато пациентите стигнат до гастроентеролога,
            диагнозата е в кърпа вързана. Как да си помогнем, докато все още е
            време, попитахме д-р Петьо Василев – гастроентеролог с дългогодишна
            практика, който работи в Медицински институт на МВР – София.
          </p>
          <p>
            <em>
              д-р Петьо Василев, гастроентеролог, Медицински институт на МВР –
              София
            </em>
          </p>
        </Col>
        <Col sm={3} xs={8}>
          <Figure className="mr-3 mb-3 w-100 float-left">
            <Figure.Image
              alt="д-р Петьо Василев, гастроентеролог, Медицински институт на МВР – София"
              src={stoinov}
            />
            <Figure.Caption></Figure.Caption>
          </Figure>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h3>Здравейте! Наистина ли е толкова важна жлъчката?</h3>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Здравейте! Непознат и доста подценяван орган е жлъчният мехур, но
            той е от изключително значение. От него се излива жлъчният сок,
            който отговаря за разграждането на мазнините, поети с храната. Това
            прави жлъчката особено важна за правилното храносмилане. Наруши ли
            се дейността ѝ, оплакванията не закъсняват.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h3>При това положение какво може да се направи?</h3>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col md={4} xs={12}>
          <Figure className="ml-3 mb-3 w-100 float-right ">
            <Figure.Image
              alt="Жлъчка"
              src={vacuum}
            />
            <Figure.Caption>

            </Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <p>
            В моя дългогодишен практически опит в гастроентерологията и в
            борбата с чернодробните заболявания и проблемите с жлъчката и
            жлъчните пътища съм прилагал различни терапевтични подходи.
            Най-честата препоръка е спазване на хранителен режим, но той не е
            достатъчен и затова се налага и прием на допълнителни средства,
            които да подпомогнат работата на жлъчката. Като ефективен и
            същевременно щадящ се открои природният продукт ХЕПАТОФЕЛИН.
            Прилагам го вече няколко години и наблюденията ми върху неговия
            ефект са отлични. Той е разработен във Франция по технология, която
            позволява бързото и пълно усвояване на полезните активни вещества от
            организма. По-ценното в случая обаче е, че единствено той съдържа и
            петте ключови растителни екстракта, които прочистват черния дроб и
            жлъчката – бял трън, артишок, глухарче, ленено семе и соя. Ефектът,
            който постигат, може да бъде сравнен с „метла“, която улавя и
            изхвърля и най-дребните отпадъци.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h3>Какво прави той?</h3>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            По отношение дейността на жлъчния мехур съставките в{" "}
            <Link to="/hepatofelin">ХЕПАТОФЕЛИН</Link>, проявяват успокоително
            действие, което води до по-бързо отшумяване на оплакванията и
            храносмилателния дискомфорт. Те улесняват работата ѝ, поддържат
            нормалното производство и изливане на жлъчните сокове. При лицата
            със сериозни нарушения, каквото е наличието на жлъчни камъни
            например, употребата на ХЕПАТОФЕЛИН е свързана с подобряване
            качеството на живот и възможност за по-пълноценно хранене. Не трябва
            да се пренебрегва обаче и влиянието на продукта върху чернодробната
            функция. Той способства стойностите на чернодробните ензими да
            влязат в норма. Прилагам го успешно при пациенти със стеатоза
            (т.нар. омазнен черен дроб), както и в случаи на цироза и други
            чернодробни проблеми. ХЕПАТОФЕЛИН се приема 2 пъти дневно по 1
            капсула след храна в продължение на поне 3 месеца без прекъсване.
          </p>
        </Col>
      </Row>

      <Row className="product-container mb-4">
        <Col>
          <Link to="/hepatofelin">
            <Image className="w-100" src={hepatofelin} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>Можете да откриете ХЕПАТОФЕЛИН в най-близката до вас аптека.</p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:0876181000">0876 18 1000</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h4>
            <Link to="/hepatofelin">
              Повече информация за ХЕПАТОФЕЛИН можете да намерите тук.
            </Link>
          </h4>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default HepatofelinInovativen;
